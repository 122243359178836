.headerr{
	width: 100%;
	margin:auto;
	background-size: cover;
	background-color: #1c1c1c;
	height: 135px;
	overflow: hidden;
}
.outer_s{
	width: 100%;
	margin:auto;
	box-shadow: 0px 6px 17px 14px rgba(13,13,13,.7);
	margin-top: 10px;
}
.h100{
	height: 100%;
}
.header_wrapper{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

}





.headerr .bottom_line{
	border-top: 1px solid rgba(110, 110, 110, 0.25);
	padding-top: 10px;
	margin-top: 20px;
	padding-bottom: 10px;
}



.crumbs{
	width: 100%;
	height: 50px;
	margin:auto;
	background-color: #1c1c1c;


}


.crumbs_wrapper ul{
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	padding-left: 0;
	list-style-type: none;
}

.crumbs_wrapper ul li{
	min-width: 30px;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.crumbs_wrapper ul li::after{
	display: flex;
	content: "";
	width: 3px;
	height: 5px;
	background: url('../img/Arrow_icon.png') no-repeat;
	align-self: center;
	margin-left: 5px;
	margin-right: 5px;
}

.crumbs_wrapper ul li , .crumbs_wrapper ul li a{
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.64px;
	color: #ffffff;
}
.breadcrumb-item.active{
	color: #858484 !important;
}

.breadcrumb-item.active a{
	color: #858484 !important;
}

.crumbs_wrapper ul li:last-child::after{
	display: none;
}


.bg_block_2{
	width: 100%;
	min-height: 1150px;
	height: auto;
	margin:auto;
	background: url("../img/BG_2.png") no-repeat;
	background-size: cover;
	margin-top: -2px;
	margin-bottom: -3px;
}

.nice_title{
	width: 100%;
	text-align: center;
	color: #ffffff;
	font-family: "Proxima Nova";
	font-size: 36px;
	font-weight: 800;
	line-height: 54px;
	letter-spacing: 1.8px;
	padding-top: 30px;
	padding-bottom: 50px;

}

.nice_title span{
	color: #3fb628;

}


.about_page_wrapper{
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
}


.about_page__desc{
	width: 480px;
	height: 431px;
	color: #ffffff;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.8px;
}

.about_page__desc p{
	margin-bottom: 20px;
}

.about_page__desc p:last-child{margin-bottom: 0;}

strong{
	color: #3fb628;
	font-weight: 500 !important;
}

	





.about_page__slider{
	width: 488px;
	height: 431px;

}





.about_page__slider__body {

    width: 488px;
    height: 431px;

}

.about_page__slider__body img{
	width: 100% !important;
	height: 100% !important;
	border-radius: 5px;
}






.about_page__slider .slick___arrow{
	top: 50%;
}


.about_page__slider .slide__prev{
	left: 20px;
}
.about_page__slider .slide__next{
	right: 20px;
}








.sertificate{
	width: 100%;
	height: auto;
	margin:auto;
	margin-bottom: -3px;
	overflow: hidden;
}


.s_title{
	margin-top: 40px;
}

.sertificate_slider{
	height: 259px;

}


.sertificate_slider__body{
	min-width: 195px !important;
	max-width: 275px !important;
	height: 259px !important;
	position: relative;
	cursor: pointer;
	margin-right: 70px;
	display: flex !important;
    align-items: center;
	object-fit: contain;

}

.sertificate_slider__body:last-child{
	margin-right: 0;
}

.sertificate_slider__body img{
	width: 100%;
	height: 259px !important;
	position: absolute;
	object-fit: contain;
	z-index: 90;

}
.sertificate_slider__body .colored{
	display: none;
}
.sertificate_slider__body:hover .colored{
	display: flex;
}
.sertificate_slider__body:hover .none_colored{
	display: none;
}



.sertificate_slider .slick___arrow{top:45%;}
.sertificate_slider .slide__prev{left: -70px;}













