.fixing_wagoon{
	width: 1280px;
	margin:auto;
}





.about_us_title{
	width: 100%;
	color: #fefefe;
	font-family: "Proxima Nova";
	font-size: 36px;
	font-weight: 800;
	line-height: 54px;
	letter-spacing: 1.8px;
	text-align: center;
}


.wagoon_block{
	width: 1049px;
	height: 315px;
	background: url('../img/tightlyps.png') no-repeat;
	background-size: cover;
	margin-top: 50px;
	position: relative;
}







.wagoon_dots {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center;
}

.wagoon_dots:hover .wagoon_desc_body{display:flex;}
//.wagoon_dots:before{animation: 2s pulse 2.5s infinite;}
.wagoon_dots:after{animation: 2s lpulse 2.5s infinite;}


	
@-webkit-keyframes genious_slider {
	0%{-webkit-transform: translate(80%, 0);}
	100%{-webkit-transform: translate(-100%, 0);}
}
@keyframes genious_slider{
	0%{transform: translate(80%, 0);}
	100%{transform: translate(-100%, 0)}
}
/*
-webkit-animation: scroll 10s infinite linear;
animation: genious_slider 10s infinite linear;
*/
.wagoon_dots::before{
	display: block;
	content: "";
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: #2973dc;
	justify-content: center;
	align-items: center;
	transition:all 1s;

}
.wagoon_dots::after{
	display: block;
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #fff;
	position: absolute;
	top: 37%;
	left: 35%;
	transition:all 1s;

}

.pulse:hover {
  animation: none;
}

@keyframes lpulse {
  0% {
    -moz-box-shadow: 0 0 0 5px rgba(41, 115, 220, 0.4);
    box-shadow: 0 0 0 5px rgba(41, 115, 220, 0.4);
  }
   25% {
    -moz-box-shadow: 0 0 0 10px rgba(41, 115, 220, 0.4);
    box-shadow: 0 0 0 10px rgba(41, 115, 220, 0.4);
  }

  100% {
      -moz-box-shadow: 0 0 0 15px rgba(41, 115, 220, 0);
      box-shadow: 0 0 0 15px rgba(41, 115, 220, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 20px rgba(41, 115, 220, 0.4);
    box-shadow: 0 0 0 20px rgba(41, 115, 220, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(41, 115, 220, 0);
      box-shadow: 0 0 0 10px rgba(41, 115, 220, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 20px rgba(41, 115, 220, 0);
      box-shadow: 0 0 0 20px rgba(41, 115, 220, 0);
  }
}


.first_dot{
    position: absolute;
    top: 65px;
    left: 60px;
}


.second_dot{
    position: absolute;
    top: 104px;
    right: 386px;

}

.third_dot{
    position: absolute;
    top: 63px;
    right: 90px;

}


.fix_wagoon_desc{
	width: 936px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	font-weight: 400;
	letter-spacing: 0.8px;
}



.wagoon_desc_body{
	display: none;
	justify-content: center;
	align-items: center;
	width: 220px;
	min-height: 207px;
	box-shadow: 0 32px 65px 8px rgba(13, 13, 13, 0.49);
	background-color: #1c1c1c;
	position: absolute;
	border-radius: 5px;	
}

.wagoon_dots_1{
	top: -30px;
	left: 30px;

}
.wagoon_dots_2{
	top: -30px;
	left: -220px;
}
.wagoon_dots_3{
	top: -33px;
	right: 35px;
}


.wagoon_desc__desc{
	margin-top: 10px;
}

.wagoon_desc_wrapper{
	width: 185px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.8px;
}




.kz_menu_2 li {
    width: 180px;
}



.wagoon_desc__desc ul li{
	color: #fff;
}

.wagoon_desc__desc ul {
    padding-left: 13px;
}


















