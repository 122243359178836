
.docs{
	width: 100%;
	height: auto;
	margin:auto;
	min-height: clac(100%);
	background-color: #1c1c1c;

}


.docs_wrapper{
	width: 100%;	
	height: auto;
	display: flex;
	justify-content: center;
	color:#fff;
	flex-direction: column;
}

.docs_desc{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}




.desc_title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}


.doc_desc_itself{
	color: rgba(255,255,255, 0.8);
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.9px;
    text-align: justify;
}


.doc_desc_itself p {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}
.doc_desc_itself p:last-child{margin-bottom: 0;}



.doc_desc_itself div{
	width: 100%;
	height: auto;
	text-align: center;
	margin-bottom: 20px;
	font-weight: 500;
	color: #fff;
}
.doc_desc_itself ol {
    margin-bottom: 20px;
}

.doc_desc_itself ul{
	list-style-type: order;
	margin: 0;
	padding: 16px;
}


.doc_desc_itself ul li{
	padding-left: 5px;
}

.tp_doc{
	margin: 20px 0;
}




.docs_itself{
	width: 889px;
	height: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap:wrap;
	margin-bottom: 50px;
}

.docs_itself a {
	width: 45%;
	color:#fff;
	text-decoration: underline;
	padding-bottom: 3px;
	min-height: 15px;
	display: flex;
	align-items: center; 
	margin-bottom: 20px;
}

.docs_itself a:hover{
	color: #3aa226;
}
.docs_itself a::before{
	display: flex;
	content:"";
	width: 18px;
	height: 20px;
	background: url('../img/Download_icon.png') no-repeat;
	margin-right:15px;

}
.docs_itself a:hover::before{
	background: url('../img/Download_icong.png') no-repeat;
}


/* Modalka */

.modal_window{
	width: 430px;
	height: 500px;
    display: none;
	background: none;
}

.modal_window .info_block {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.modal_window .info_block {
    width: 325px;
    height: 325px;
}
.modal_window .just_info{
	height: 270px;
}


.modal_window .just_info .phones_map{
	height: 170px;
}



.fancybox-button svg {
    display: none
}

.close_btn {
    width: 30px;
    height: 40px;
    position: absolute;
    right: 15px;
    top: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.close_btn::before {
    content: 'X';
    font-size: 35px;
    font-family: Arial, sans-serif;
    color: #fff;
}

.close_btn:hover:before {
    cursor: pointer;
    color: #000
}
.wrong_inp {
    border: 3px solid #f55!important
}
.contacts .separate_block:last-child {
    width: 240px;
}
.good_inp {
    border: 1px solid #0c047d!important
}
.show{display:block;}
.hide_it{display:none;}

/* Modalka */




/*Styling Selectbox*/
.dropdown {
  width: 40px;
  display: inline-block;
  border-radius: 5px;
  transition: all .5s ease;
  position: relative;
  font-size: 14px;
  color: #fff;
  height: 35px;
  text-align: left
}
.dropdown .select {
    cursor: pointer;
    display: block;
    padding: 10px
}
.dropdown .select > i {
    font-size: 13px;
    color: #888;
    cursor: pointer;
    transition: all .3s ease-in-out;
    float: right;
    line-height: 20px
}
.dropdown:hover {
    box-shadow: 0 0 4px rgb(204, 204, 204)
}
.dropdown:active {
    background-color: #f8f8f8
}
.dropdown.active .select > i {
    transform: rotate(-90deg)
}
.dropdown .dropdown-menu {
    position: absolute;
    background-color: #fff;
    width: 100%;
    left: 0;
    bottom: -70px;
    box-shadow: 0 1px 2px rgb(204, 204, 204);
    border-radius: 0 1px 5px 5px;
    overflow: hidden;
    display: none;
    max-height: 144px;
    overflow-y: auto;
    z-index: 9;
}
.dropdown .dropdown-menu a {
    transition: all .2s ease-in-out;
    cursor: pointer;
    width: 40px;
    height: 35px;
    z-index: 999;
    text-transform: uppercase;
    z-index: 999999;
    color: #858484;
} 
.dropdown .dropdown-menu {
    padding: 0;
    list-style: none;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dropdown .dropdown-menu a:hover {
    background-color: #f2f2f2
}
.dropdown .dropdown-menu a:active {
    background-color: #e2e2e2;
}



.lang {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}