/*normolize defaults styles for correct displaying*/
@import url(partials/fonts.css);
* {
  margin: 0;
  box-sizing: border-box; }

body, html {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeueCyr";
  font-weight: 400; }

a {
  text-decoration: none; }

a, button:focus, input {
  outline: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

main {
  display: block; }

h1 {
  font-size: 2em; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace,monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b, strong {
  font-weight: bolder; }

code, kbd, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none; }

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: .35em .75em .625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

/*normolize defaults styles for correct displaying*/
button, input, optgroup, select, textarea {
  background: none;
  border: none;
  outline: none; }

.homie {
  width: 100%;
  margin: auto;
  background: url("../img/BG.png") no-repeat;
  background-size: cover;
  height: 718px; }

.h100 {
  height: 100%; }

.homie_wrapper {
  width: 100%;
  height: 618px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.container {
  width: 1005px;
  margin: auto; }

.header {
  width: 100%;
  padding-top: 15px;
  height: 125px; }

.top_line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.bottom_line {
  width: 100%;
  margin-top: 30px; }

.navbar {
  width: 100%; }

.nav_ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  list-style-type: none; }

.nav_ul li {
  width: 100px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.64px;
  text-transform: uppercase; }

.nav_ul li:hover a {
  color: #3fb628;
  font-weight: 700; }

.active_li a {
  color: #3fb628 !important;
  font-weight: 700; }

.nav_ul li a {
  text-decoration: none;
  color: #ffffff; }

.langs {
  appearance: none;
  background: url(../img/Arrow_ion.png) 100% 54% no-repeat;
  display: flex;
  width: 32px;
  height: 28px;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.6px; }

.phone_block {
  min-width: 190px;
  max-width: 235px;
  max-height: 60px;
  min-height: 30px;
  display: flex;
  flex-wrap: wrap; }

.phones {
  width: 140px; }

.phone_block::before {
  display: flex;
  content: "";
  background: url("../img/Phone_ion_1.png") no-repeat;
  width: 37px;
  height: 37px;
  align-self: center;
  margin-right: 15px; }

.phones a {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 18px; }

.phone_desc {
  color: #858484;
  font-size: 11px;
  letter-spacing: 0.55px;
  text-transform: uppercase; }

.modal_btn {
  width: 180px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3fb628;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  cursor: pointer; }

.promo_title {
  width: 635px;
  color: #ffffff;
  font-family: "Proxima Nova"; }

.promo_title h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 2.4px; }

.modal_btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 42px;
  background-color: #3fb628;
  margin-top: 50px;
  border-radius: 3px;
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.6px;
  cursor: pointer; }

.advantages {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: space-beetween;
  align-items: center; }

.adv_item {
  width: 339px;
  height: 89px;
  display: flex; }

.advanced_title {
  width: 175px;
  height: 45px;
  color: #ffffff;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.95px; }

.adv_item:last-child .advanced_title {
  width: 264px; }

.adv_item:last-child .adv_desc {
  width: 180px; }

.adv_desc_block {
  padding-left: 30px; }

.adv_desc {
  width: 175px;
  color: #ffffff;
  font-family: "HelveticaNeueCyr";
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.64px; }

.kz_ph .phone_block {
  min-width: 240px !important; }

.p50 {
  padding-top: 50px !important; }

.our_service {
  width: 100%;
  height: 350px;
  margin: auto;
  background-color: #1c1c1c; }

.our_service_wrapper {
  width: 1280px; }

.our_srvc_title {
  width: 100%;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 36px;
  font-weight: 800;
  line-height: 54px;
  color: #ffffff;
  letter-spacing: 1.8px;
  padding-top: 50px; }

.green_color {
  color: #3fb628;
  font-weight: 800; }

.our_srvc_logo {
  margin-bottom: 25px; }

._1 {
  background: url("../img/1_white.png") no-repeat;
  width: 79px;
  height: 46px; }

._2 {
  background: url("../img/2_white.png") no-repeat;
  width: 67px;
  height: 47px; }

._3 {
  background: url("../img/3_white.png") no-repeat;
  width: 67px;
  height: 47px; }

._4 {
  background: url("../img/4_white.png") no-repeat;
  width: 68px;
  height: 42px; }

._5 {
  background: url("../img/5_white.png") no-repeat;
  width: 60px;
  height: 40px; }

.our_srvc__item:hover ._1 {
  background: url("../img/1_green.png") no-repeat; }

.our_srvc__item:hover ._2 {
  background: url("../img/2_green.png") no-repeat; }

.our_srvc__item:hover ._3 {
  background: url("../img/3_green.png") no-repeat; }

.our_srvc__item:hover ._4 {
  background: url("../img/4_green.png") no-repeat; }

.our_srvc__item:hover ._5 {
  background: url("../img/5_green.png") no-repeat; }

.our_srv_items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px; }

.our_srvc__item {
  width: 201px;
  height: 207px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.our_srvc__item_info {
  width: 201px;
  height: 207px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.our_srvc__item::after {
  display: none;
  content: "";
  min-width: 6px;
  min-height: 6px;
  background-color: #3fb628;
  border-radius: 50%;
  margin-bottom: 20px; }

.our_srvc__item:hover {
  box-shadow: 0 32px 65px 8px rgba(13, 13, 13, 0.49); }

.our_srvc__item:hover::after {
  display: block; }

.our_srvc_desc {
  width: 127px;
  color: #ffffff;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 700;
  line-height: 21.88px;
  letter-spacing: 0.95px;
  text-align: center;
  padding-top: 10px; }

.our_srvc__item:last-child .our_srvc_desc {
  width: 155px; }

.bg_block {
  width: 100%;
  background: url(../img/BG2.png) 0 65px no-repeat;
  background-size: cover;
  height: 1300px;
  margin: auto;
  background-color: #1c1c1c; }

.about_us {
  width: 1280px;
  margin: auto; }

.about_us_title {
  width: 100%;
  font-family: "Proxima Nova";
  font-size: 36px;
  font-weight: 800;
  line-height: 54px;
  color: #ffffff;
  letter-spacing: 1.8px;
  text-align: center;
  padding: 60px; }

.about_us_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.left_block {
  width: 482px;
  height: 167px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.8px; }

.right_block {
  width: 454px;
  height: 253px;
  background: url("../img/blue_map.png") no-repeat;
  background-size: contain;
  position: relative; }

.whitemap {
  background: url("../img/white_map.png") no-repeat;
  background-size: contain; }

.bluemap {
  background: url("../img/blue_map.png") no-repeat;
  background-size: contain; }

.nani:hover + .right_block {
  background: url("../img/white_map.png") no-repeat;
  background-size: contain; }

.c_1 {
  position: absolute;
  top: -50px;
  right: 28%; }

.c_2 {
  position: absolute;
  top: -7%;
  left: -10%; }

.c_3 {
  position: absolute;
  bottom: 0;
  left: 4%; }

.c_4 {
  position: absolute;
  top: 35%;
  left: 55%;
  transform: translate(-50%, 50%); }

.country_name {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
  font-family: 'Proxima Nova';
  font-weight: 700; }

.country_name:hover {
  color: #2973DC; }

.read_more {
  width: 115px;
  height: 25px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.8px;
  background: url("../img/Arroe_icon.png") 100% 50% no-repeat;
  margin-top: 40px; }

.read_more::after {
  display: block;
  content: "";
  width: 50%;
  height: 2px;
  border-radius: 1px;
  background-color: #3fb628;
  margin-top: 5px; }

.read_more a {
  color: #ffffff; }

.fixing_wagoon {
  width: 1280px;
  margin: auto; }

.about_us_title {
  width: 100%;
  color: #fefefe;
  font-family: "Proxima Nova";
  font-size: 36px;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 1.8px;
  text-align: center; }

.wagoon_block {
  width: 1049px;
  height: 315px;
  background: url("../img/tightlyps.png") no-repeat;
  background-size: cover;
  margin-top: 50px;
  position: relative; }

.wagoon_dots {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center; }

.wagoon_dots:hover .wagoon_desc_body {
  display: flex; }

.wagoon_dots:after {
  animation: 2s lpulse 2.5s infinite; }

@-webkit-keyframes genious_slider {
  0% {
    -webkit-transform: translate(80%, 0); }
  100% {
    -webkit-transform: translate(-100%, 0); } }

@keyframes genious_slider {
  0% {
    transform: translate(80%, 0); }
  100% {
    transform: translate(-100%, 0); } }

/*
-webkit-animation: scroll 10s infinite linear;
animation: genious_slider 10s infinite linear;
*/
.wagoon_dots::before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #2973dc;
  justify-content: center;
  align-items: center;
  transition: all 1s; }

.wagoon_dots::after {
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 37%;
  left: 35%;
  transition: all 1s; }

.pulse:hover {
  animation: none; }

@keyframes lpulse {
  0% {
    -moz-box-shadow: 0 0 0 5px rgba(41, 115, 220, 0.4);
    box-shadow: 0 0 0 5px rgba(41, 115, 220, 0.4); }
  25% {
    -moz-box-shadow: 0 0 0 10px rgba(41, 115, 220, 0.4);
    box-shadow: 0 0 0 10px rgba(41, 115, 220, 0.4); }
  100% {
    -moz-box-shadow: 0 0 0 15px rgba(41, 115, 220, 0);
    box-shadow: 0 0 0 15px rgba(41, 115, 220, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 20px rgba(41, 115, 220, 0.4);
    box-shadow: 0 0 0 20px rgba(41, 115, 220, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(41, 115, 220, 0);
    box-shadow: 0 0 0 10px rgba(41, 115, 220, 0); }
  100% {
    -moz-box-shadow: 0 0 0 20px rgba(41, 115, 220, 0);
    box-shadow: 0 0 0 20px rgba(41, 115, 220, 0); } }

.first_dot {
  position: absolute;
  top: 65px;
  left: 60px; }

.second_dot {
  position: absolute;
  top: 104px;
  right: 386px; }

.third_dot {
  position: absolute;
  top: 63px;
  right: 90px; }

.fix_wagoon_desc {
  width: 936px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.8px; }

.wagoon_desc_body {
  display: none;
  justify-content: center;
  align-items: center;
  width: 220px;
  min-height: 207px;
  box-shadow: 0 32px 65px 8px rgba(13, 13, 13, 0.49);
  background-color: #1c1c1c;
  position: absolute;
  border-radius: 5px; }

.wagoon_dots_1 {
  top: -30px;
  left: 30px; }

.wagoon_dots_2 {
  top: -30px;
  left: -220px; }

.wagoon_dots_3 {
  top: -33px;
  right: 35px; }

.wagoon_desc__desc {
  margin-top: 10px; }

.wagoon_desc_wrapper {
  width: 185px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.8px; }

.kz_menu_2 li {
  width: 180px; }

.wagoon_desc__desc ul li {
  color: #fff; }

.wagoon_desc__desc ul {
  padding-left: 13px; }

.our_clients {
  width: 1280px;
  margin: auto;
  height: 190px; }

.our_clients_slider {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  animation: genious_slider 20s infinite linear; }

.some_other_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.our_clients_title {
  margin-top: 100px;
  width: 100%;
  font-family: "Proxima Nova";
  font-size: 36px;
  font-weight: 800;
  line-height: 54px;
  color: #fefefe;
  letter-spacing: 1.8px;
  text-align: center;
  margin-bottom: 20px; }

.our_clients_title span {
  color: #3fb628; }

.our_clients_body {
  width: auto !important;
  position: relative !important;
  margin-right: 40px;
  display: flex !important;
  justify-content: center;
  height: 70px;
  align-items: center; }

.our_clients_body:hover .first_part {
  display: none; }

.our_clients_body:hover .second_part {
  display: flex; }

.second_part {
  display: none; }

.slide__prev {
  display: flex;
  content: "";
  width: 13px;
  height: 19px;
  background: url("../img/Arrow_left.png") no-repeat;
  background-size: cover;
  left: -40px; }

.slide__prev:hover {
  background: url("../img/Arroe_icon.png") no-repeat;
  transform: scaleX(-1) !important; }

.slide__next:hover {
  background: url("../img/Arroe_icon.png") no-repeat;
  transform: scaleX(1) !important; }

.slide__next {
  display: flex;
  content: "";
  width: 13px;
  height: 19px;
  background: url("../img/Arrow_left.png") no-repeat;
  background-size: cover;
  transform: scaleX(-1) !important;
  right: -70px; }

.slick___arrow {
  position: absolute;
  top: 35%;
  height: 20px;
  cursor: pointer;
  z-index: 9; }

.contacts {
  width: 100%;
  margin: auto;
  height: 490px;
  position: relative; }

#map {
  width: 100%;
  height: 100%; }

.info_block {
  position: absolute;
  top: 25px;
  right: 150px;
  width: 650px;
  height: 450px;
  display: flex; }

.left_side {
  width: 325px;
  height: 100%;
  background: #ffffff;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.right_side {
  width: 325px;
  height: 100%;
  background: #1c1c1c;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column; }

.side_title {
  width: 100%;
  color: #1c1c1c;
  font-family: "Proxima Nova";
  font-size: 29px;
  font-weight: 800;
  letter-spacing: 1.8px;
  text-align: center; }

.bl {
  color: #ffffff; }

.form_ask {
  width: 100%;
  height: 360px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding-left: 15px; }

.group_name {
  width: 180px;
  height: 65px;
  margin-right: 20px; }

.form_ask label {
  width: 49px;
  height: 8px;
  color: #1c1c1c;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px; }

.btn_ask {
  width: 180px;
  height: 42px;
  background-color: #3fb628;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3fb628;
  border-radius: 3px;
  margin-top: 25px;
  font-weight: 700;
  cursor: pointer;
  margin-right: 15px; }

.form-styling {
  width: 180px;
  height: 42px;
  border-radius: 3px;
  border: 1px solid #e7e8e7;
  background-color: #ffffff;
  color: #1c1c1c;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.6px;
  padding-left: 15px; }

input:focus, textarea:focus {
  border: 1px solid #3fb628; }

.form-styling textarea {
  height: 60px;
  resize: none; }

textarea {
  height: 60px;
  resize: none; }

.just_info {
  width: 245px;
  height: auto;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.7px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 245px;
  margin-bottom: 30px;
  margin-top: 20px; }

.logo img {
  width: 100%;
  height: 100%; }

.just_info .address {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between; }

.just_info .address::before {
  display: flex;
  content: "";
  background: url("../img/Location_icon.png") no-repeat;
  min-width: 12px;
  min-height: 16px;
  align-self: flex-start;
  margin-top: 5px;
  margin-right: 30px; }

.email {
  color: #ffffff;
  font-size: 13px;
  display: flex; }

.just_info .email::before {
  display: flex;
  content: "";
  background: url("../img/Email_icon.png") no-repeat;
  min-width: 16px;
  min-height: 14px;
  align-self: flex-start;
  margin-top: 5px;
  margin-right: 28px; }

.just_info .phones_map {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between; }

.just_info .phone_block_map {
  width: 100%;
  display: flex;
  flex-direction: column; }

.just_info .phones {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.just_info .phones a, .just_info .phone_desc {
  font-size: 13px;
  text-transform: none;
  width: 100%;
  margin-bottom: 10px; }

.just_info .phones_map::before {
  display: flex;
  content: "";
  background: url("../img/Phone_icon.png") no-repeat;
  min-width: 16px;
  min-height: 16px;
  align-self: center;
  align-self: flex-start;
  margin-top: 5px;
  margin-right: 26px; }

.footer {
  width: 100%;
  height: 185px;
  background-color: #1c1c1c;
  margin: auto; }

.footer_top_line {
  height: 120px;
  border-bottom: 1px solid #6e6e6e;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.logo {
  width: 150px;
  height: 55px; }

.footer_nav {
  width: 340px;
  height: 58px; }

.footer_nav nav ul {
  width: 285px;
  flex-wrap: wrap; }

.kz_menu {
  height: 90px; }

.kz_menu .nav_ul li {
  width: 140px; }

.footer_bottom_line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.6px;
  height: 65px;
  align-items: center; }

.devops {
  width: 260px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  justify-content: flex-end; }

.devops:after {
  display: flex;
  content: "";
  width: 52px;
  height: 22px;
  background: url(../img/devop.png);
  background-size: contain;
  margin-left: 20px; }

.contact_title {
  width: 100%;
  height: auto;
  margin: auto;
  background-color: #1c1c1c; }

.ct_title {
  padding-top: 20px; }

.just_info .phones_map {
  height: auto; }

.headerr {
  width: 100%;
  margin: auto;
  background-size: cover;
  background-color: #1c1c1c;
  height: 135px;
  overflow: hidden; }

.outer_s {
  width: 100%;
  margin: auto;
  box-shadow: 0px 6px 17px 14px rgba(13, 13, 13, 0.7);
  margin-top: 10px; }

.h100 {
  height: 100%; }

.header_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.headerr .bottom_line {
  border-top: 1px solid rgba(110, 110, 110, 0.25);
  padding-top: 10px;
  margin-top: 20px;
  padding-bottom: 10px; }

.crumbs {
  width: 100%;
  height: 50px;
  margin: auto;
  background-color: #1c1c1c; }

.crumbs_wrapper ul {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 0;
  list-style-type: none; }

.crumbs_wrapper ul li {
  min-width: 30px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.crumbs_wrapper ul li::after {
  display: flex;
  content: "";
  width: 3px;
  height: 5px;
  background: url("../img/Arrow_icon.png") no-repeat;
  align-self: center;
  margin-left: 5px;
  margin-right: 5px; }

.crumbs_wrapper ul li, .crumbs_wrapper ul li a {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.64px;
  color: #ffffff; }

.breadcrumb-item.active {
  color: #858484 !important; }

.breadcrumb-item.active a {
  color: #858484 !important; }

.crumbs_wrapper ul li:last-child::after {
  display: none; }

.bg_block_2 {
  width: 100%;
  min-height: 1150px;
  height: auto;
  margin: auto;
  background: url("../img/BG_2.png") no-repeat;
  background-size: cover;
  margin-top: -2px;
  margin-bottom: -3px; }

.nice_title {
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-family: "Proxima Nova";
  font-size: 36px;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 1.8px;
  padding-top: 30px;
  padding-bottom: 50px; }

.nice_title span {
  color: #3fb628; }

.about_page_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px; }

.about_page__desc {
  width: 480px;
  height: 431px;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.8px; }

.about_page__desc p {
  margin-bottom: 20px; }

.about_page__desc p:last-child {
  margin-bottom: 0; }

strong {
  color: #3fb628;
  font-weight: 500 !important; }

.about_page__slider {
  width: 488px;
  height: 431px; }

.about_page__slider__body {
  width: 488px;
  height: 431px; }

.about_page__slider__body img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px; }

.about_page__slider .slick___arrow {
  top: 50%; }

.about_page__slider .slide__prev {
  left: 20px; }

.about_page__slider .slide__next {
  right: 20px; }

.sertificate {
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: -3px;
  overflow: hidden; }

.s_title {
  margin-top: 40px; }

.sertificate_slider {
  height: 259px; }

.sertificate_slider__body {
  min-width: 195px !important;
  max-width: 275px !important;
  height: 259px !important;
  position: relative;
  cursor: pointer;
  margin-right: 70px;
  display: flex !important;
  align-items: center;
  object-fit: contain; }

.sertificate_slider__body:last-child {
  margin-right: 0; }

.sertificate_slider__body img {
  width: 100%;
  height: 259px !important;
  position: absolute;
  object-fit: contain;
  z-index: 90; }

.sertificate_slider__body .colored {
  display: none; }

.sertificate_slider__body:hover .colored {
  display: flex; }

.sertificate_slider__body:hover .none_colored {
  display: none; }

.sertificate_slider .slick___arrow {
  top: 45%; }

.sertificate_slider .slide__prev {
  left: -70px; }

.service_page .our_srvc__item:hover {
  box-shadow: none; }

.service_page {
  height: calc(100% - 135px - 50px - 185px);
  min-height: 433px; }

.service_page .our_srvc__item::after {
  display: none; }

.service_page .our_srv_items {
  margin-top: 0; }

.service_page .our_srvc_logo {
  margin-bottom: 0; }

.service_page .our_srvc__item {
  width: 201px;
  height: 264px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative; }

.service_page .our_srv_items {
  margin-top: 0; }

.service_page .our_srvc__item::after {
  margin-bottom: 0; }

.our_srvc__item_info:hover .our_srvc_item__info_desc {
  display: flex; }

.our_srvc_item__info_desc {
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 201px;
  height: auto;
  box-shadow: 0 0 26px 4px rgba(13, 13, 13, 0.49);
  background-color: #1c1c1c;
  position: absolute;
  bottom: -30px;
  left: 0;
  padding: 10px; }

.our_srvc_item__info_desc::after {
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 2px solid #1c1c1c;
  border-left: 2px solid #1c1c1c;
  top: -9px;
  left: 50%;
  content: "";
  transform: rotate(45deg);
  background: #1c1c1c;
  margin-bottom: 0; }

.our_srvc_item__info_desc p {
  width: 176px;
  color: #ffffff;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.75px; }

.docs {
  width: 100%;
  height: auto;
  margin: auto;
  min-height: clac(100%);
  background-color: #1c1c1c; }

.docs_wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  color: #fff;
  flex-direction: column; }

.docs_desc {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.desc_title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px; }

.doc_desc_itself {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.9px;
  text-align: justify; }

.doc_desc_itself p {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap; }

.doc_desc_itself p:last-child {
  margin-bottom: 0; }

.doc_desc_itself div {
  width: 100%;
  height: auto;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
  color: #fff; }

.doc_desc_itself ol {
  margin-bottom: 20px; }

.doc_desc_itself ul {
  list-style-type: order;
  margin: 0;
  padding: 16px; }

.doc_desc_itself ul li {
  padding-left: 5px; }

.tp_doc {
  margin: 20px 0; }

.docs_itself {
  width: 889px;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px; }

.docs_itself a {
  width: 45%;
  color: #fff;
  text-decoration: underline;
  padding-bottom: 3px;
  min-height: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px; }

.docs_itself a:hover {
  color: #3aa226; }

.docs_itself a::before {
  display: flex;
  content: "";
  width: 18px;
  height: 20px;
  background: url("../img/Download_icon.png") no-repeat;
  margin-right: 15px; }

.docs_itself a:hover::before {
  background: url("../img/Download_icong.png") no-repeat; }

/* Modalka */
.modal_window {
  width: 430px;
  height: 500px;
  display: none;
  background: none; }

.modal_window .info_block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.modal_window .info_block {
  width: 325px;
  height: 325px; }

.modal_window .just_info {
  height: 270px; }

.modal_window .just_info .phones_map {
  height: 170px; }

.fancybox-button svg {
  display: none; }

.close_btn {
  width: 30px;
  height: 40px;
  position: absolute;
  right: 15px;
  top: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.close_btn::before {
  content: 'X';
  font-size: 35px;
  font-family: Arial, sans-serif;
  color: #fff; }

.close_btn:hover:before {
  cursor: pointer;
  color: #000; }

.wrong_inp {
  border: 3px solid #f55 !important; }

.contacts .separate_block:last-child {
  width: 240px; }

.good_inp {
  border: 1px solid #0c047d !important; }

.show {
  display: block; }

.hide_it {
  display: none; }

/* Modalka */
/*Styling Selectbox*/
.dropdown {
  width: 40px;
  display: inline-block;
  border-radius: 5px;
  transition: all .5s ease;
  position: relative;
  font-size: 14px;
  color: #fff;
  height: 35px;
  text-align: left; }

.dropdown .select {
  cursor: pointer;
  display: block;
  padding: 10px; }

.dropdown .select > i {
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all .3s ease-in-out;
  float: right;
  line-height: 20px; }

.dropdown:hover {
  box-shadow: 0 0 4px #cccccc; }

.dropdown:active {
  background-color: #f8f8f8; }

.dropdown.active .select > i {
  transform: rotate(-90deg); }

.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  bottom: -70px;
  box-shadow: 0 1px 2px #cccccc;
  border-radius: 0 1px 5px 5px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9; }

.dropdown .dropdown-menu a {
  transition: all .2s ease-in-out;
  cursor: pointer;
  width: 40px;
  height: 35px;
  z-index: 999;
  text-transform: uppercase;
  z-index: 999999;
  color: #858484; }

.dropdown .dropdown-menu {
  padding: 0;
  list-style: none;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.dropdown .dropdown-menu a:hover {
  background-color: #f2f2f2; }

.dropdown .dropdown-menu a:active {
  background-color: #e2e2e2; }

.lang {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }
