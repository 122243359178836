.footer{
	width: 100%;
	height: 185px;
	background-color: #1c1c1c;
	margin: auto;
}




.footer_top_line{

	height: 120px;
	border-bottom: 1px solid #6e6e6e;
	display: flex;
	justify-content: space-between;
	align-items: center;
}



.logo {

    width: 150px;
    height: 55px;

}


.footer_nav{
	width: 340px;
	height: 58px;
}

.footer_nav nav ul{
	width: 285px;
	flex-wrap: wrap;
}

.kz_menu {
	height: 90px;
}

.kz_menu .nav_ul li {
    width: 140px;
}
.footer_bottom_line{
	width: 100%;
	display: flex;
	justify-content: space-between;
	color: #ffffff;
	font-size: 12px;
	line-height: 22px;
	letter-spacing: 0.6px;
	height: 65px;
	align-items: center;
}












.devops {
    width: 260px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;
    justify-content: flex-end
}

.devops:after {
    display: flex;
    content: "";
    width: 52px;
    height: 22px;
    background: url(../img/devop.png);
    background-size: contain;
    margin-left: 20px
}