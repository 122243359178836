




.p50{padding-top: 50px !important;}

.our_service{
	width: 100%;
	height: 350px;
	margin:auto;
	background-color: #1c1c1c;
}
.our_service_wrapper{
	width: 1280px;
}

.our_srvc_title{
	width: 100%;
	text-align: center;
	font-family: "Proxima Nova";
	font-size: 36px;
	font-weight: 800;
	line-height: 54px;
	color: #ffffff;
	letter-spacing: 1.8px;
	padding-top: 50px;

}

.green_color{
	color: #3fb628;
	font-weight: 800;
}


.our_srvc_logo {
    margin-bottom: 25px;
}


._1{
	background: url('../img/1_white.png') no-repeat;
	width: 79px;
	height: 46px;
}
._2{
	background: url('../img/2_white.png') no-repeat;
	width: 67px;
	height: 47px;
}
._3{
	background: url('../img/3_white.png') no-repeat;
	width: 67px;
	height: 47px;
}
._4{
	background: url('../img/4_white.png') no-repeat;
	width: 68px;
	height: 42px;
}
._5{
	background: url('../img/5_white.png') no-repeat;
	width: 60px;
	height: 40px;
}



.our_srvc__item:hover ._1{
	background: url('../img/1_green.png') no-repeat;
}
.our_srvc__item:hover ._2{
	background: url('../img/2_green.png') no-repeat;
}
.our_srvc__item:hover ._3{
	background: url('../img/3_green.png') no-repeat;
}
.our_srvc__item:hover ._4{
	background: url('../img/4_green.png') no-repeat;
}
.our_srvc__item:hover ._5{
	background: url('../img/5_green.png') no-repeat;
}
.our_srv_items{	
	display: flex;
	flex-wrap: wrap;
	margin-top: 60px;
}

.our_srvc__item{
	width: 201px;
	height: 207px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

}
.our_srvc__item_info{
	width: 201px;
	height: 207px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.our_srvc__item::after{
	display:none;
	content: "";
	min-width: 6px;
	min-height: 6px;
	background-color: #3fb628;
	border-radius: 50%;
	margin-bottom: 20px;
}
.our_srvc__item:hover{
	box-shadow: 0 32px 65px 8px rgba(13, 13, 13, 0.49);
}

.our_srvc__item:hover::after{
	display: block;
}

.our_srvc_desc{
	width: 127px;
	color: #ffffff;
	font-family: "Proxima Nova";
	font-size: 18px;
	font-weight: 700;
	line-height: 21.88px;
	letter-spacing: 0.95px;
	text-align: center;
	padding-top: 10px;
}


.our_srvc__item:last-child .our_srvc_desc {

    width: 155px;

}







