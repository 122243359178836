
.our_clients{
	width: 1280px;
	margin:auto;
	height: 190px;
}



.our_clients_slider{
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-between;
	animation: genious_slider 20s infinite linear;

}

.some_other_wrapper{
	width: 100%;
	height: 100%;
	overflow: hidden;
}


.our_clients_title{
	margin-top: 100px;
	width: 100%;
	font-family: "Proxima Nova";
	font-size: 36px;
	font-weight: 800;
	line-height: 54px;
	color: #fefefe;
	letter-spacing: 1.8px;
	text-align: center;
	margin-bottom: 20px;
}
.our_clients_title span {
	color:#3fb628;
}


.our_clients_body {
	width: auto !important;
    position: relative !important;
    margin-right: 40px;
    display: flex !important;
	justify-content: center;
	height: 70px;
	align-items: center;

}
.our_clients_body:hover .first_part{display: none;}
.our_clients_body:hover .second_part{display: flex;}


.second_part {
   display: none;
}


.slide__prev{
	display:flex;
	content:"";
	width:13px;
	height:19px;
	background: url("../img/Arrow_left.png") no-repeat;
	background-size: cover;
	left: -40px;
}

.slide__prev:hover{
	background: url("../img/Arroe_icon.png") no-repeat;
	transform: scaleX(-1) !important;

}
.slide__next:hover{
	background: url("../img/Arroe_icon.png") no-repeat;
	transform: scaleX(1) !important;
	
}
.slide__next{
	display:flex;
	content:"";
	width:13px;
	height:19px;
	background: url("../img/Arrow_left.png") no-repeat;
	background-size: cover;
	transform: scaleX(-1) !important;
	right: -70px;
}







.slick___arrow {
	position: absolute;
	top: 35%;
	height: 20px;
	cursor: pointer;
	z-index: 9;
}
















