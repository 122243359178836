button, input, optgroup, select, textarea {
    background: none;
    border: none;
    outline: none;
}
.homie{
	width: 100%;
	margin:auto;
	background: url('../img/BG.png') no-repeat;
	background-size: cover;
	height: 718px;
}
.h100{
	height: 100%;
}
.homie_wrapper{
	width: 100%;
	height: 618px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.container{
	width: 1005px;
	margin:auto;
}


.header{
	width: 100%;
	padding-top: 15px;
	height: 125px;
}

.top_line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottom_line {
    width: 100%;
    margin-top: 30px;
}

.navbar {

    width: 100%;

}




.nav_ul{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    list-style-type: none;
}

.nav_ul li {
	width: 100px;
	font-size: 12px;
	line-height: 22px;
	letter-spacing: 0.64px;	
	text-transform: uppercase;
}

.nav_ul li:hover a{
	color: #3fb628;
	font-weight: 700;
}

.active_li a{
	color: #3fb628 !important;
	font-weight: 700;

}

.nav_ul li a {
	text-decoration: none;
	color: #ffffff;
}

.langs {
	appearance:none;
	background: url(../img/Arrow_ion.png) 100% 54% no-repeat;
	display: flex;
	width: 32px;
	height: 28px;
	align-items: center;
	cursor:pointer;
	color: #ffffff;
	font-size: 12px;
	font-weight: 500;
	line-height: 22px;
	text-transform: uppercase;
	letter-spacing: 0.6px;
}


.phone_block{
	min-width:190px;
	max-width: 235px;
	max-height: 60px;
	min-height: 30px;
	display: flex;
	flex-wrap: wrap;
}

.phones{width: 140px;}

.phone_block::before{
	display: flex;
	content: "";
	background: url('../img/Phone_ion_1.png') no-repeat;
	width: 37px;
	height: 37px;
	align-self: center;
	margin-right: 15px;
}
.phones a {
	color: #ffffff;
	font-size: 14px;
	letter-spacing: 0.7px;
	line-height: 18px;
}


.phone_desc{
	color: #858484;
	font-size: 11px;
	letter-spacing: 0.55px;
	text-transform: uppercase;
}



.modal_btn{
	width: 180px;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #3fb628;
	border-radius: 5px;
	color: #ffffff;
	font-size: 12px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.6px;
	text-transform: uppercase;
	cursor: pointer;
}



.promo_title{
	width: 635px;
	color: #ffffff;
	font-family: "Proxima Nova";
}

.promo_title h1 {
	font-size: 48px;
	font-weight: 700;
	line-height: 54px;
	letter-spacing: 2.4px;
}


.modal_btn2{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 180px;
	height: 42px;
	background-color: #3fb628;
	margin-top: 50px;
	border-radius: 3px;
	color: #1c1c1c;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0.6px;
	cursor: pointer;
}


.advantages{
	width: 100%;
	height: 110px;
	display: flex;
	justify-content: space-beetween;
	align-items: center;
}



.adv_item{
	width: 339px;
	height: 89px;
	display: flex;
}


.advanced_title{
	width: 175px;
	height: 45px;
	color: #ffffff;
	font-family: "Proxima Nova";
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0.95px;
}
.adv_item:last-child .advanced_title{
	width: 264px;

}
.adv_item:last-child .adv_desc{width: 180px;}
.adv_desc_block {
    padding-left: 30px;
}


.adv_desc {
	width: 175px;
	color: #ffffff;
	font-family: "HelveticaNeueCyr";
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.64px;
}






.kz_ph .phone_block {
    min-width: 240px !important;}






