.bg_block{
	width: 100%;
	background: url(../img/BG2.png) 0 65px no-repeat;
	background-size: cover;
	height: 1300px;
	margin:auto;
	background-color: #1c1c1c;

}

.about_us{
	width: 1280px;
	margin:auto;

}


.about_us_title{
	width: 100%;
	font-family: "Proxima Nova";
	font-size: 36px;
	font-weight: 800;
	line-height: 54px;
	color: #ffffff;
	letter-spacing: 1.8px;
	text-align: center;
	padding: 60px;
}
.about_us_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}




.left_block{
	width: 482px;
	height: 167px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.8px;
}




.right_block{
	width: 454px;
	height: 253px;
	background: url('../img/blue_map.png')no-repeat;
	background-size: contain;
	position: relative;
}
.whitemap{
	background: url('../img/white_map.png')no-repeat;
	background-size: contain;	
}
.bluemap{
	background: url('../img/blue_map.png')no-repeat;
	background-size: contain;	
}
.nani:hover + .right_block{
	background: url('../img/white_map.png')no-repeat;
	background-size: contain;
}
.c_1 {
	position: absolute;
	top: -50px;
	right: 28%;
}
.c_2 {
	position: absolute;
	top: -7%;
	left: -10%;
}
.c_3 {
	position: absolute;
	bottom: 0;
	left: 4%;
}
.c_4 {
	position: absolute;
	top: 35%;
	left: 55%;
	transform: translate(-50%,50%);
}

.country_name{
	color: #fff;
	text-transform: uppercase;
	font-size: 20px;
	cursor: pointer;
	font-family: 'Proxima Nova';
	font-weight: 700;
}

.country_name:hover{
	color: #2973DC;
}


.read_more{
	width: 115px;
	height: 25px;
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0.8px;
	background: url('../img/Arroe_icon.png') 100% 50% no-repeat;
	margin-top:40px;

}
.read_more::after{
	display: block;
	content: "";
	width: 50%;
	height: 2px;
	border-radius: 1px;
	background-color: #3fb628;
	margin-top: 5px;
}

.read_more a{
	color: #ffffff;
}





