.service_page .our_srvc__item:hover{
	box-shadow: none;
}

.service_page {
    height: calc(100% - 135px - 50px - 185px );
    min-height: 433px;
}

.service_page .our_srvc__item::after{
	display: none;

}

.service_page .our_srv_items{margin-top: 0;}

.service_page .our_srvc_logo {
    margin-bottom: 0;
}

.service_page .our_srvc__item{
	width: 201px;
	height: 264px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
}



.service_page .our_srv_items{	
	margin-top: 0;
}

.service_page .our_srvc__item::after{
	margin-bottom: 0;
}

.our_srvc__item_info:hover .our_srvc_item__info_desc{
	display: flex;
}


.our_srvc_item__info_desc{
	display: none;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 201px;
	height:auto;
	box-shadow: 0 0 26px 4px rgba(13, 13, 13, 0.49);
	background-color: #1c1c1c;
	position: absolute;
	bottom: -30px;
	left: 0;
	padding: 10px;

}



.our_srvc_item__info_desc::after {
    position: absolute;
    width: 15px;
    height: 15px;
    border-top: 2px solid #1c1c1c;
    border-left: 2px solid #1c1c1c;
    top: -9px;
    left: 50%;
    content: "";
    transform: rotate(45deg);
    background: #1c1c1c;
	margin-bottom: 0;
}


.our_srvc_item__info_desc p{
	width: 176px;
	color: #ffffff;
	font-family: "Proxima Nova";
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.75px;
}





























