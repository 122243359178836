.contact_title{
	width: 100%;
	height: auto;
	margin:auto;
	background-color: #1c1c1c;

}





.ct_title{
	padding-top: 20px;
}


.just_info .phones_map{
	height: auto;
}
