
@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('../fonts/HelveticaNeueCyr-Medium.eot');
	src: local('HelveticaNeueCyr-Medium'),
		url('../fonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueCyr-Medium.woff') format('woff'),
		url('../fonts/HelveticaNeueCyr-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('../fonts/HelveticaNeueCyr-Black.eot');
	src: local('HelveticaNeueCyr-Black'),
		url('../fonts/HelveticaNeueCyr-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueCyr-Black.woff') format('woff'),
		url('../fonts/HelveticaNeueCyr-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}


@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('../fonts/HelveticaNeueCyr-Roman.eot');
	src: local('HelveticaNeueCyr-Roman'),
		url('../fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
		url('../fonts/HelveticaNeueCyr-Roman.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('../fonts/HelveticaNeueCyr-Bold.eot');
	src: local('HelveticaNeueCyr-Bold'),
		url('../fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueCyr-Bold.woff') format('woff'),
		url('../fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('../fonts/HelveticaNeueCyr-Light.eot');
	src: local('HelveticaNeueCyr-Light'),
		url('../fonts/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueCyr-Light.woff') format('woff'),
		url('../fonts/HelveticaNeueCyr-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('../fonts/HelveticaNeueCyr-Thin.eot');
	src: local('HelveticaNeueCyr-Thin'),
		url('../fonts/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueCyr-Thin.woff') format('woff'),
		url('../fonts/HelveticaNeueCyr-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}


@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Regular.eot');
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
		url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Regular.woff') format('woff'),
		url('../fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}



@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Bold.eot');
	src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
		url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Bold.woff') format('woff'),
		url('../fonts/ProximaNova-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Extrabld.eot');
	src: local('Proxima Nova Bold'), local('ProximaNova-Extrabld'),
		url('../fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Extrabld.woff') format('woff'),
		url('../fonts/ProximaNova-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNovaT-Thin.eot');
	src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
		url('../fonts/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNovaT-Thin.woff') format('woff'),
		url('../fonts/ProximaNovaT-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}



@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Light.eot');
	src: local('Proxima Nova Light'), local('ProximaNova-Light'),
		url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Light.woff') format('woff'),
		url('../fonts/ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

/* This stylesheet generated by Transfonter (https://transfonter.org) on August 23, 2017 4:44 PM */

@font-face {
	font-family: 'Segoe UI';
	src: url('../fonts/SegoeUI-Light.eot');
	src: local('Segoe UI Light'), local('SegoeUI-Light'),
		url('../fonts/SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SegoeUI-Light.woff') format('woff'),
		url('../fonts/SegoeUI-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}


@font-face {
	font-family: 'Segoe UI';
	src: url('../fonts/SegoeUI-SemiBold.eot');
	src: local('Segoe UI Semibold'), local('SegoeUI-SemiBold'),
		url('../fonts/SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SegoeUI-SemiBold.woff') format('woff'),
		url('../fonts/SegoeUI-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI';
	src: url('../fonts/SegoeUI.eot');
	src: local('Segoe UI'), local('SegoeUI'),
		url('../fonts/SegoeUI.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SegoeUI.woff') format('woff'),
		url('../fonts/SegoeUI.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI';
	src: url('../fonts/SegoeUI-Bold.eot');
	src: local('Segoe UI Bold'), local('SegoeUI-Bold'),
		url('../fonts/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SegoeUI-Bold.woff') format('woff'),
		url('../fonts/SegoeUI-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

