.contacts{
	width: 100%;
	margin:auto;
	height: 490px;
	position: relative;

}



#map{
	width: 100%;
	height: 100%;

}


.info_block{
	position: absolute;
	top: 25px;
	right: 150px;
	width: 650px;
	height: 450px;
	display: flex;


}

.left_side{
	width: 325px;
	height: 100%;
	background: #ffffff;
	padding: 30px;
	border-radius: 5px;	
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}


.right_side{
	width: 325px;
	height: 100%;
	background: #1c1c1c;
	padding: 30px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
}


.side_title{
	width: 100%;
	color: #1c1c1c;
	font-family: "Proxima Nova";
	font-size: 29px;
	font-weight: 800;
	letter-spacing: 1.8px;
	text-align: center;
}


.bl{
	color: #ffffff;
}

.form_ask {
    width: 100%;
    height: 360px;
    display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	padding-left: 15px;

}

.group_name{
	width: 180px;
	height: 65px;
	margin-right: 20px;
}


.form_ask label{
	width: 49px;
	height: 8px;
	color: #1c1c1c;
	font-size: 10px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.5px;
}







.btn_ask {
	width: 180px;
	height: 42px;
	background-color: #3fb628;
    display: flex;
    justify-content: center;
    align-items: center;
	background-color: #3fb628;
	border-radius: 3px;
	margin-top: 25px;
	font-weight: 700;
	cursor: pointer;
	margin-right: 15px;
}

.form-styling{
	width: 180px;
	height: 42px;
	border-radius: 3px;
	border: 1px solid #e7e8e7;
	background-color: #ffffff;
	color: #1c1c1c;
	font-size: 12px;
	line-height: 22px;
	letter-spacing: 0.6px;
	padding-left: 15px;
}

input:focus, textarea:focus{
	border: 1px solid #3fb628;

}
.form-styling textarea{height: 60px;resize: none;}

textarea{height: 60px;resize: none;}
.just_info{
	width: 245px;
	height: auto;
	color: #ffffff;
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.7px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	min-height: 245px;
	margin-bottom: 30px;
	margin-top: 20px;
}
.logo img {
    width: 100%;
    height: 100%;
}

.just_info .address{
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;
}

.just_info .address::before{
	display: flex;
	content: "";
	background: url('../img/Location_icon.png') no-repeat;
	min-width: 12px;
	min-height: 16px;
	align-self: flex-start;
	margin-top: 5px;
	margin-right: 30px;

}

.email{
	color: #ffffff;
	font-size: 13px;
	display: flex;
}
.just_info .email::before{
	display: flex;
	content: "";
	background: url('../img/Email_icon.png') no-repeat;
	min-width: 16px;
	min-height: 14px;
	align-self: flex-start;
	margin-top: 5px;
	margin-right: 28px;

}






.just_info .phones_map{
	width: 100%;
	height: 120px;
	display: flex;
	justify-content: space-between;
}

.just_info .phone_block_map{
	width: 100%;
	display: flex;
	flex-direction: column;
}
.just_info .phones{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.just_info .phones a, .just_info .phone_desc{
	font-size: 13px;
	text-transform: none;
	width: 100%;
	margin-bottom: 10px;
}

.just_info .phones_map::before{
	display: flex;
	content: "";
	background: url('../img/Phone_icon.png') no-repeat;
	min-width: 16px;
	min-height: 16px;
	align-self: center;
	align-self: flex-start;
	margin-top: 5px;
	margin-right: 26px;

}











